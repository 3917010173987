<template>
  <div
    :class="['innerbanner', isLanding ? 'mt-0 d-block': '']"
    :style="
      imageSrc
        ? `background-image: url(${imageSrc})`
        : `background-image: url(${speedSizeDomain}/assets/img/terminal02.jpg)`
    "
    v-if="!hideDealCondition"
  ></div>
  <!-- <div
    class="innerbanner"
    :style="'background-image: url(${speedSizeDomain}/assets/img/search-result-banner.png); background-size: 100% 100%;z-index: inherit;'"
    v-if="!isAgencyLogin"
  ></div> -->
    <div style="height: 50px;" v-else></div>
</template>
<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  props: {
    banner: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
    }),
    imageSrc() {
      if (this.banner && (typeof this.banner === 'string' || this.banner instanceof String)) {
        return this.banner?.replaceAll(' ', '%20');
      }
      return '';
    },
    hideDealCondition() {
      const { isAgencyLogin, isOdysseySite, isMaccabiAgency } = this;
      return !isMaccabiAgency && isAgencyLogin && !isOdysseySite;
    },
  },
};
</script>
<style scoped>
  .innerbanner{
    margin-top: 3.5rem;
  }

  .bonauf-mobile .innerbanner {
    display: none;
  }

  @media (max-width: 768px) {
  .innerbanner {
      min-height: 72px !important;
      background-size: contain;
      background-position: center center;
  }
  }
</style>
